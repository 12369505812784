@import "assets/scss/fonts";
@import '~material-design-icons/iconfont/material-icons.css';

:root, .darkmode {
  --bg-color: #1f1f1f;
  --font-color: #e9e9e9;
  --title-color: #fec150;
  --title-background: #222;
  --main-border: 1px solid #151515;
  --main-bg: #2b2b2b;
  --main-button: #ffc769;
  --main-button-hover: #f3b243;
  --main-button-font: #1f1f1f;
  --main-button-active: 0 0 0 3px white; // outer box-shadow
  --hr: rgba(255, 255, 255, 0.2);
}

:root {
  --main-button-radius: 5px;
}


/*.lightmode {
  --bg-color: #fec150;
  --font-color: #222;
  --title-color: #e0820e;
  --title-background: #fff;
  --main-border: 1px solid rgba(1, 1, 1, 0.2);
  --main-bg: rgb(255, 239, 195);
  --main-button: #a9a9a9;
  --main-button-hover: #949494;
  --main-button-font: #1f1f1f;
  --main-button-active: 3px solid var(--title-color);
  --hr: rgba(1, 1, 1, 0.3);
}

.blue-theme {
  background: rgb(37, 151, 212);
  background: radial-gradient(circle, rgba(0, 212, 255, .8) 0%, rgba(11, 42, 120, .8) 70%, rgba(9, 4, 84, .8) 100%);
  --main-button: orange;
  --main-button-hover: #ffca6f;
  --main-button-font: #1f1f1f;
}

.red-theme {
  --main-button: orange;
  --main-button-hover: #ffca6f;
  --main-button-font: #1f1f1f;
}*/

html, body {
  height: 100%;
}

html * {
  font-weight: 400;
}

p, button {
  &:focus {
    outline: none !important;
  }
}

hr {
  border-top: 1px solid var(--hr) !important;
}

.mat-tooltip {
  font-size: .9rem !important;
}

.lk-button {
  height: 100%;
  font-size: 16px;
  padding: 7px 15px;
  border: var(--main-border);
  border-radius: var(--main-button-radius);
  color: var(--main-button-font);
  background-color: var(--main-button);

  transition: 120ms ease-out;

  &.outline {
    color: var(--main-button);
    border-color: var(--main-button);
    background-color: transparent;
  }

  &:hover, &.active {
    color: var(--main-button-font);
    background-color: var(--main-button-hover);
  }

  &:disabled {
    text-decoration: line-through;
    background-color: #414141;
    color: #181818;
  }
}

#back-button {
  display: flex;
  padding: 7px;
  border: none;
  margin-bottom: 10px;
  background-color: var(--bg-color);
  border-radius: 5px;

  transition: 100ms;

  &:hover {
    background-color: #4a4a4a;
  }
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1,
.col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto,
.col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9,
.col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7,
.col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5,
.col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

a {
  color: var(--title-color) !important;
}

.row {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.multiline-tooltip {
  white-space: pre-line;
}

.form-control {
  color: var(--font-color) !important;
  background-color: var(--bg-color) !important;
}

.error-text {
  color: #ee1d1d;
}

.active {
  box-shadow: var(--main-button-active) !important;
}

.lk-spinner {
  margin: auto;
  border: 7px solid var(--title-color);
  border-top: 7px solid #535353;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  transition: .5s;

  animation: custom-spin 1.5s linear infinite;
}

@-webkit-keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dialog-close-button {
  padding: 5px;
  float: right;
  background-color: transparent;
  border: none;
  color: var(--font-color);
}

.form-control:focus {
  border-color: var(--title-color) !important;
  box-shadow: 0 0 0 1.5px var(--title-color) !important;
}

.ma-shan-zheng {
  font-family: 'Ma Shan Zheng', cursive !important;

  p {
    font-size: 21px;
  }
}

.noto-sans {
  font-family: 'Noto Sans SC', sans-serif !important;
}

.noto-serif {
  font-family: 'Noto Serif SC', serif !important;
}

.material-icons {
  font-size: 28px;
  user-select: none;
}

.larger-mat-dialog .mat-dialog-container {
  max-width: 100vw !important;
}

/* Toggle button*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a5a5a5;

  transition: 200ms;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;

  transition: 200ms;
}

input:checked + .slider {
  background-color: var(--title-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--title-color);
}

input:checked + .slider:before {
  transform: translateX(32px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(3px);
}

