/* noto-serif-sc-regular - latin_chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-regular.svg#NotoSerifSC') format('svg'); /* Legacy iOS */
}
/* noto-serif-sc-700 - latin_chinese-simplified */
@font-face {
  font-family: 'Noto Serif SC';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-serif/noto-serif-sc-v22-latin_chinese-simplified-700.svg#NotoSerifSC') format('svg'); /* Legacy iOS */
}

/* noto-sans-sc-regular - latin_chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-regular.svg#NotoSansSC') format('svg'); /* Legacy iOS */
}
/* noto-sans-sc-700 - latin_chinese-simplified */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-sans/noto-sans-sc-v26-latin_chinese-simplified-700.svg#NotoSansSC') format('svg'); /* Legacy iOS */
}

/* ma-shan-zheng-regular - latin_chinese-simplified */
@font-face {
  font-family: 'Ma Shan Zheng';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ma-shan-zheng/ma-shan-zheng-v10-latin_chinese-simplified-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/ma-shan-zheng/ma-shan-zheng-v10-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/ma-shan-zheng/ma-shan-zheng-v10-latin_chinese-simplified-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/ma-shan-zheng/ma-shan-zheng-v10-latin_chinese-simplified-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/ma-shan-zheng/ma-shan-zheng-v10-latin_chinese-simplified-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/ma-shan-zheng/ma-shan-zheng-v10-latin_chinese-simplified-regular.svg#MaShanZheng') format('svg'); /* Legacy iOS */
}
